<template>
	<layout-default>
		<div>
			<ProgressSpinner v-if="loading == true"></ProgressSpinner>

			<div class="uk-container uk-container-large" v-if="loading == false">
				<div class="p-mb-6"></div>
				<div class="uk-grid uk-grid-small" v-match-heights="{ el: ['.matchheights'] }">
					<div class="uk-width-expand">
						<div class="intro p-mb-6 matchheights">
							<div v-if="$i18n.locale == 'fr'">
								<h2>Services en ligne du PPG</h2>
								<p>
									Dans les services en ligne du PPG, vous pouvez commander et demander rapidement et facilement divers produits et services. Le site parle de lui-même, ne commandez que ce dont vous avez vraiment besoin. Nous essayons toujours de maintenir nos stocks à jour. Si vous
									avez des questions, veuillez contacter le bureau de service du PPG. Pour vous servir encore mieux, le portail des services en ligne sera enrichi de nouveaux services au cours de l'année à venir.
								</p>
								<p><b>PPG Servicedesk Uithoorn:</b> <a href="mailto:servicedesk.nl@ppg.com" class="t-fg-white">servicedesk.nl@ppg.com</a></p>
								<p><b>PPG Servicedesk Belgique:</b> <a href="mailto:servicedesk.be@ppg.com" class="t-fg-white">servicedesk.be@ppg.com</a></p>
								<p></p>
							</div>
							<div v-else>
								<h2>PPG Online Services</h2>
								<p>
									In de PPG online services kunt u diverse producten en diensten snel en eenvoudig bestellen en aanvragen. De site spreekt voor zichzelf, bestel alleen hetgeen u echt nodig heeft. Wij proberen de voorraden altijd up to date te houden. Indien u vragen heeft dan kunt
									u deze stellen aan de PPG Servicedesk. Om u nog beter van dienst te kunnen zijn zal de Online Services portal het komend jaar verder worden uitgebreid met nieuwe diensten.
								</p>
								<p><b>PPG Servicedesk Uithoorn:</b> <a href="mailto:servicedesk.nl@ppg.com" class="t-fg-white">servicedesk.nl@ppg.com</a></p>
								<p><b>PPG Servicedesk Hasselt:</b> <a href="mailto:servicedesk.be@ppg.com" class="t-fg-white">servicedesk.be@ppg.com</a></p>
								<p></p>
							</div>
						</div>
					</div>
					<div class="uk-width-1-2 uk-visible@m">
						<div class="matchheights">
							<VueperSlides class="no-shadow thumbnails" ref="vueperslides2" :slide-ratio="10 / 16" :visible-slides="1" :bullets="false" :arrows="true">
								<VueperSlide v-for="(slide, i) in slides" :key="i" :image="slide.image" :title="''" />
							</VueperSlides>
						</div>
					</div>
				</div>

				<div class="uk-grid uk-grid-small uk-flex-center">
					<div class="uk-width-1-2 uk-width-1-3@m uk-width-1-5@l" v-for="tile in filteredSections" :key="tile.$index" @click="clickTile(tile)" v-if="sectionId == 0">
						<div class="tile p-mb-3 hvr-underline-from-center" :class="tileActive.id == tile.id ? 'active' : ''">
							<div class="uk-position-center">{{ getLanguageField(tile, "title") }}</div>
						</div>
					</div>
				</div>

				<div id="subtiles_scroll_anchor"></div>

				<div class="uk-grid uk-grid-small uk-flex-center" v-if="sectionId != 0">
					<div class="uk-width-1-2 uk-width-1-3@m uk-width-1-5@l" @click="$router.push({ name: 'home', params: { id: null } })">
						<div class="tile active p-mb-6 hvr-underline-from-center">
							<div class="uk-position-center">
								<div class="uk-padding-small">
									<i class="pi pi-arrow-left"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="uk-width-1-2 uk-width-1-3@m uk-width-1-5@l" v-for="tile in filteredSubSections" :key="tile.$index" @click="openEndpoint(tile)">
						<div class="tile p-mb-6 hvr-underline-from-center">
							<div class="uk-position-center">
								<div class="uk-padding-small">
									{{ getLanguageField(tile, "title") }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import axios from "axios";

import "vueperslides/dist/vueperslides.css";
import { VueperSlides, VueperSlide } from "vueperslides";

import ProgressSpinner from "primevue/progressspinner";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import DataView from "primevue/dataview";
import Toast from "primevue/toast";
import Message from "primevue/message";

import VueAos from "vue-aos";

export default {
	components: {
		LayoutDefault,
		Directus,
		axios,

		VueperSlides,
		VueperSlide,

		ProgressSpinner,
		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		DataView,
		Toast,
		Message,

		VueAos,
	},
	data() {
		return {
			loading: false,
			slides: [{ image: require("@/assets/slides/shutterstock_569210155.jpg") }, { image: require("@/assets/slides/shutterstock_1577063569.jpg") }, { image: require("@/assets/slides/shutterstock_1698304030.jpg") }],
			tileActive: {},
			sections: [],
			user_sections: [],
			sectionId: 0,
		};
	},
	props: {},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		showSubs(tile) {
			let self = this;
			if (self.tileActive && self.tileActive.id == tile.id) {
				self.tileActive = {};
			} else {
				self.tileActive = JSON.parse(JSON.stringify(tile));
				self.$scrollTo("#subtiles_scroll_anchor", 500, {});
			}
		},
		gotoSubs(tile) {
			let self = this;
			self.sectionId = tile.id;
			self.$router.push({ name: "home", params: { id: tile.id } });
		},
		hasSubs(tile) {
			let self = this;

			let sections = JSON.parse(JSON.stringify(self.sections));
			sections = sections.filter(function(section) {
				if (section.parent) {
					if (section.parent.id == tile.id) {
						return true;
					}
				}
				return false;
			});
			if (sections.length > 0) {
				return true;
			}
			return false;
		},
		clickTile(tile) {
			if (this.hasSubs(tile)) {
				this.gotoSubs(tile);
			} else {
				this.openEndpoint(tile);
			}
		},
		openEndpoint(tile) {
			let self = this;

			axios({
				method: "post",
				url: process.env.VUE_APP_REMOTE_ADDR + "/endpoints/" + tile.endpoint_id.endpoint + "/",
				data: {
					path: tile.url,
					token: Directus.getToken(),
				},
			})
				.then(function(response) {
					//handle success
					console.log(response);
					if (response.data && response.data.endpoint) {
						window.open(response.data.endpoint);
					}
				})
				.catch(function(response) {
					//handle error
					console.log(response);
				});
		},
	},
	computed: {
		filteredSections: function() {
			let self = this;
			const sections = JSON.parse(JSON.stringify(self.sections));
			return sections.filter(function(section) {
				if (section.parent != null && section.parent != "") {
					return false;
				}
				let index = self.user_sections.findIndex(function(user_section) {
					return user_section.section_id == section.id;
				});
				if (index >= 0) {
					return true;
				}
				return false;
			});
		},
		filteredSubSections: function() {
			let self = this;

			const sections = JSON.parse(JSON.stringify(self.sections));
			return sections.filter(function(section) {
				if (section.parent && section.parent.id && self.sectionId) {
					console.log(section.parent);
					if (section.parent.id == self.sectionId) {
						let index = self.user_sections.findIndex(function(user_section) {
							return user_section.section_id == section.id;
						});
						if (index >= 0) {
							return true;
						}
						return false;
					}
				}
				return false;
			});
		},
	},
	created() {
		this.loading = false;

		Directus.client.api
			.get("/items/user", {
				"filter[directus_user][eq]": this.$route.params.user.id,
				fields: "*,sections.section_id",
			})
			.then((response) => {
				this.user_sections = response.data[0].sections;
			});

		Directus.client.api
			.get("/items/section", {
				"filter[status][eq]": "published",
				fields: "*.*,*.*.*",
			})
			.then((response) => {
				this.sections = response.data;
			});

		/*
		Directus.client.api
			.post("/users/invite", {
				email: "alex@devolkers.nl",
			})
			.then((response) => {
				console.log("invite", response);
			});

		Directus.client.api
			.post("/mail", {
				to: ["alex@devolkers.nl"],
				subject: "New Password",
				body: "Hello <b>{{name}}</b>, this is your new password: {{password}}.",
				type: "html",
				data: {
					name: "John Doe",
					password: "secret",
				},
			})
			.then((response) => {
				console.log("invite", response);
			});
			*/
	},
	mounted: function() {},
	watch: {
		"$route.params.id": {
			handler: function(id) {
				this.sectionId = id ? id : 0;
				this.sectionId = parseInt(this.sectionId);
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.p-tabview .p-tabview-panels {
	background: transparent !important;
	padding: 0;
}
.p-tabview .p-tabview-nav {
	background: transparent !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background: transparent !important;
}

.tile {
	cursor: pointer;
}
.vueperslide {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
.vueperslide__title {
	font-size: 3vw;
	text-align: left;
	width: 100%;
	position: absolute;
	bottom: 0px;
	padding: 1vw;
	color: #fff;
	opacity: 0.8;
	font-weight: 900;
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 0px 4px rgba(0, 0, 0, 0.3);
}
</style>
