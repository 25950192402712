<template>
	<div class="mainMenuBar" id="mainMenuBar" :class="{ sticky: stickyNavActive }">
		<Menubar :model="items">
			<template #start>
				<div class="uk-grid uk-grid-small uk-flex-middle">
					<div class="uk-width-auto">
						<a href="/"><img src="@/assets/ppg_logo_white.svg" class="p-ml-1" style="width:50px;"/></a>
					</div>
					<div class="uk-width-auto p-text-bold t-fg-white p-mr-2 uk-visible@s">
						<a href="/" style="text-decoration:none;" class="t-fg-white">{{ $t("common.websiteName") }}</a>
					</div>
				</div>
			</template>

			<template #end>
				<LocaleSwitcher />
				<div class="p-d-inline-block">
					<Button :label="user.first_name" class="p-button-sm" icon="pi pi-user" @click="$refs.userMenu.toggle($event)"></Button>
					<Menu ref="userMenu" :model="itemsUser" :popup="true" />
				</div>
			</template>
		</Menubar>
	</div>
</template>

<style>
.p-menubar-button {
	display: none !important;
}
</style>

<script>
import Directus from "@/services/directus/";
import LocaleSwitcher from "@/components/i18n/LocaleSwitcher";

import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import Button from "primevue/button";

export default {
	name: "NavBar",
	components: {
		Directus,
		LocaleSwitcher,

		Menubar,
		Menu,
		Button,
	},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		hierarchy(data = [], { idKey = "id", parentKey = "parent", childrenKey = "items" } = {}) {
			const tree = [];
			const childrenOf = {};
			data.forEach((item) => {
				item.label = this.getLanguageField(item, "title");
				//
				const { [idKey]: id, [parentKey]: parentId = 0 } = item;
				childrenOf[id] = childrenOf[id] || [];
				item[childrenKey] = childrenOf[id];
				if (parentId) {
					if (!childrenOf[parentId["id"]]) {
						childrenOf[parentId["id"]] = [];
					}
					childrenOf[parentId["id"]].push(item);
				} else {
					tree.push(item);
				}
			});
			tree.forEach((item) => {
				if (item[childrenKey].length == 0) {
					item[childrenKey] = null;
					item.to = { name: item.slug, params: { lang: this.$i18n.locale == "nl" ? null : this.$i18n.locale, menu: item } };
				} else {
					item[childrenKey].forEach((item2) => {
						if (item2[childrenKey].length == 0) {
							item2[childrenKey] = null;
							item2.to = { name: item2.slug, params: { lang: this.$i18n.locale == "nl" ? null : this.$i18n.locale, menu: item } };
						} else {
						}
					});
				}
			});
			return tree;
		},
	},
	data() {
		return {
			stickyNavActive: false,
			user: {},
			items: [],
		};
	},
	mounted() {
		window.document.onscroll = () => {
			let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			let navBar = document.getElementById("mainMenuBar");
			if (window.scrollY > navBar.offsetTop + navBar.offsetHeight) {
				if (this.lastScroll > currentScroll) {
					this.stickyNavActive = true;
				} else {
					this.stickyNavActive = false;
				}
			} else {
				this.stickyNavActive = false;
			}
			this.lastScroll = currentScroll;
		};
	},
	created() {
		this.user = this.$route.params.user;

		this.menu = [];
		this.items = [];
		/*
		Directus.client.api
			.get("/items/menu", {
				"filter[status][eq]": "published",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.menu = response.data;

				this.items = this.hierarchy(this.menu);
			});

		*/

		this.itemsUser = [
			{
				label: "Sign out",
				command: (event) => {
					Directus.signOut().then((response) => {
						console.log(response);
						this.$cookie.delete("auth");
						location.href = "/";
					});
				},
			},
		];
	},

	watch: {
		$route(to, from) {
			this.promotionId = this.$route.params.id;
			this.items = this.hierarchy(this.menu);
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style lang="scss"></style>
