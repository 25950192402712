<template>
	<layout-login>
		<div class="ppgbackground_login">
			<div class="p-shadow-17" style="width:600px;max-width:90%;margin:100px auto;">
				<Card v-if="directus_user">
					<template slot="header">
						<div class="t-bg-1 p-p-2">
							<div class="uk-grid uk-grid-small uk-flex-middle">
								<div class="uk-width-auto">
									<img src="@/assets/ppg_logo_white.svg" class="p-ml-1" style="width:50px;" />
								</div>
								<div class="uk-width-auto p-text-bold t-fg-white">
									{{ $t("common.websiteName") }}
								</div>
								<div class="uk-width-expand"></div>
								<div class="uk-width-auto uk-hidden">
									<LocaleSwitcher />
								</div>
							</div>
						</div>
						<div style="text-align:center;">
							<img src="@/assets/undraw/undraw_Access_account_re_8spm.svg" class="" style="width:250px;margin:20px;" />
						</div>
					</template>
					<template slot="title">
						<div style="text-align:center;" class="p-mb-2">{{ $t("account.welcome") }} {{ directus_user.first_name }} {{ directus_user.last_name }},</div>
					</template>
					<template slot="content">
						<ValidationObserver ref="observer-resetpassword">
							<div>
								<div class="p-mb-6" style="text-align:center;">
									{{ $t("account.chooseNewPassword") }}
								</div>
								<div class="p-fluid p-grid p-pl-6 p-pr-6">
									<div class="p-field p-col-12">
										<div>
											<ValidationProvider v-slot="v" rules="required|min:6|max:18" :name="$t('account.password')">
												<span class="p-float-label p-input-icon-right">
													<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
													<InputText v-model="user.password" type="password" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
													<label>{{ $t("account.password") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
									<div class="p-field p-col-12">
										<div>
											<ValidationProvider v-slot="v" :rules="'required|is:' + user.password" :name="$t('account.password')">
												<span class="p-float-label p-input-icon-right">
													<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
													<InputText v-model="user.password2" type="password" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
													<label>{{ $t("account.repeat") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</div>
						</ValidationObserver>
						<Message v-if="responseError" severity="error">{{ responseError }}</Message>
					</template>
					<template slot="footer">
						<div class="uk-grid uk-grid-small uk-flex-bottom">
							<div class="uk-width-auto@s p-mb-2">
								<Button class="p-button-lg" :icon="busy == true ? 'pi pi-spin pi-spinner' : 'pi pi-save'" :label="$t('account.save')" @click="setNewPassword()" />
							</div>
							<div class="uk-width-expand@s uk-flex-first@s p-mb-2"></div>
						</div>
					</template>
				</Card>
			</div>

			<Toast />
		</div>
	</layout-login>
</template>

<script>
const axios = require("axios");

import LayoutLogin from "@/layout/Login.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import LocaleSwitcher from "@/components/i18n/LocaleSwitcher";

import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Message from "primevue/message";

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

export default {
	components: {
		LayoutLogin,
		Directus,

		LocaleSwitcher,

		Card,
		Button,
		InputText,
		Toast,
		Message,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			user: {
				email: "",
				password: "",
				password2: "",
			},
			responseError: null,
			externalId: null,
			directus_user: null,
			busy: false,
			loading: true,
		};
	},
	props: {
		nextUrl: String,
	},
	methods: {
		setNewPassword() {
			let self = this;
			let observer = self.$refs["observer-resetpassword"];
			//console.log(observer);
			if (observer.flags.valid == true) {
				let token = self.$route.query.token;

				Axios.post(self.remote_addr + "/development/directus/setpassword/", {
					user: self.externalId,
					id: self.directus_user.id,
					password: self.user.password,
				})
					.then((response) => {
						console.log("response", response);
						if (response && response.data && response.data.status) {
							this.$router.push({ name: "login", params: { email: self.directus_user.email, password: self.user.password } });
						}
					})
					.catch((error) => {
						this.$router.push({ name: "login" });
					});
			}
		},
	},
	mounted: function() {},
	created() {
		this.externalId = this.$route.params.external_id;

		if (this.$route && this.$route.params && this.$route.params.lang) {
			this.$cookie.set("locale", this.$route.params.lang, "1y");
			this.$i18n.locale = this.$cookie.get("locale");
		} else {
			this.$cookie.set("locale", "nl", "1y");
			this.$i18n.locale = this.$cookie.get("locale");
		}

		let self = this;
		Axios.get(self.remote_addr + "/development/directus/user/?user=" + self.externalId)
			.then((response) => {
				console.log("response", response);
				if (response && response.data && response.data.status && response.data.status == "success") {
					//self.$router.push({ name: "login" });
					self.directus_user = response.data.data;
					self.loading = false;
				} else {
					this.$router.push({ name: "login" });
				}
			})
			.catch((error) => {
				this.$router.push({ name: "login" });
			});
	},
	watch: {
		$route(to, from) {
			this.externalId = this.$route.params.external_id;
			this.menu = this.$route.params.menu;
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style></style>
