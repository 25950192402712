import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueLodash from "vue-lodash";
import lodash from "lodash";

import "@/scss/main.scss";
import "font-awesome/scss/font-awesome.scss";

Vue.config.productionTip = false;

import ToastService from "primevue/toastservice";
Vue.use(ToastService);

import Tooltip from "primevue/tooltip";
Vue.directive("tooltip", Tooltip);

import VueCookie from "vue-cookie";
Vue.use(VueCookie);

import i18n from "./i18n";

Vue.use(VueLodash, { lodash: lodash });

import { ValidationObserver, ValidationProvider } from "vee-validate";
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import VueQrcode from "@chenfengyuan/vue-qrcode";

Vue.component(VueQrcode.name, VueQrcode);

import VueMatchHeights from "vue-match-heights";

Vue.use(VueMatchHeights, {
	disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

import AspectRatio from "v-aspect-ratio";

Vue.use(AspectRatio);

import VueMasonry from "vue-masonry-css";

Vue.use(VueMasonry);

const moment = require("moment");
require("moment/locale/nl");

Vue.use(require("vue-moment"), {
	moment,
});

//console.log(router);

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

// usage: {{ file.size | prettyBytes }}
Vue.filter("prettyBytes", function(num) {
	// jacked from: https://github.com/sindresorhus/pretty-bytes
	if (typeof num !== "number" || isNaN(num)) {
		throw new TypeError("Expected a number");
	}

	var exponent;
	var unit;
	var neg = num < 0;
	var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	if (neg) {
		num = -num;
	}

	if (num < 1) {
		return (neg ? "-" : "") + num + " B";
	}

	exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
	unit = units[exponent];

	return (neg ? "-" : "") + num + " " + unit;
});

new Vue({
	router,
	i18n,
	render: function(h) {
		return h(App);
	},
}).$mount("#app");
