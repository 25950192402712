import DirectusSDK from "@directus/sdk-js";
import axios from "axios";
import VueCookie from "vue-cookie";

const remote_addr = process.env.VUE_APP_REMOTE_ADDR;
const directus_project = process.env.VUE_APP_DIRECTUS_PROJECT;

const client = new DirectusSDK({
	url: remote_addr + "/directus/public/",
	project: directus_project,

	/*
	persist: true,
	tokenExpirationTime: 5 * 60 * 60 * 1000,
	storage: window.localStorage
	*/
});

export default {
	remote_addr,
	client,

	getToken() {
		let auth = VueCookie.get("auth");
		if (auth != null) {
			auth = JSON.parse(auth);
			if (auth && auth.data && auth.data.token) {
				return auth.data.token;
			}
		} else {
			return null;
		}
	},

	signOut() {
		return client.logout().then((response) => {
			return response;
		});
	},

	signIn(email, password) {
		return client
			.login({
				email: email,
				password: password,
				mode: "cookie",
			})
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.log(error.message);
				return {
					error: {
						message: error.message,
					},
				};
			});
	},

	sendPasswordRecoveryEmail(email) {
		return axios
			.post(
				remote_addr + "/directus/public/" + directus_project + "/auth/password/request",
				{
					email: email,
					reset_url: remote_addr + "/resetpassword",
				},
				{ validateStatus: false }
			)

			.then((response) => {
				return response;
			});
	},

	setNewPassword(password, token) {
		return axios
			.post(
				remote_addr + "/directus/public/" + directus_project + "/auth/password/reset",
				{
					password: password,
					token: token,
				},
				{ validateStatus: false }
			)

			.then((response) => {
				return response;
			});
	},

	getCurrentUser(token) {
		client.config.token = token;
		return client
			.getMe()
			.then((response) => {
				return response;
			})
			.catch((error) => {
				return null;
			});
	},

	getLanguageField(obj, field, locale) {
		if (typeof obj != "undefined") {
			if (obj.translation) {
				let languages = obj.translation;
				let language = self._.find(languages, { language: locale, status: "published" });
				if (language && language[field]) {
					return language[field];
				}
			}
			return obj[field];
		} else {
			return field;
		}
	},
};
