<template>
	<div class="LayoutDefault">
		<NavBar />

		<div>
			<div>
				<slot />
			</div>
		</div>

		<div class="uk-width-1-1 t-bg-2 t-fg-white p-p-6">
			<div class="uk-grid">
				<div class="uk-width-auto@l p-mb-3">
					<strong>PPG Coatings Nederland B.V.</strong>
				</div>
				<div class="uk-width-expand@l p-mb-3"></div>
				<div class="uk-width-auto@l p-mb-3">Amsterdamseweg 14 | PO BOX 42 | 1420 AA | Uithoorn | The Netherlands <strong>T </strong> + 31 (0)297 54 12 60 | <strong>F </strong> + 31 (0)297 54 14 79</div>
				<div class="uk-width-expand@l p-mb-3"></div>
				<div class="uk-width-auto@l p-mb-3">
					<a href="www.ppg.com" target="_blank" class="t-fg-white">www.ppg.com</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/layout/NavBar";

export default {
	components: {
		NavBar,
	},
};
</script>

<style scoped></style>
