import Vue from "vue";
import VueRouter from "vue-router";
import LayoutDefault from "../layout/Default.vue";
import LayoutLogin from "../layout/Login.vue";

import Directus from "@/services/directus/";

import Home from "../views/home/";
import Welcome from "../views/welcome/";

import login from "../views/login/login.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/:lang([a-z][a-z])?/login",
		name: "login",
		component: login,
		meta: { layout: LayoutLogin, requiresAuth: false },
	},
	{
		path: "/:lang([a-z][a-z])?/resetpassword",
		name: "resetpassword",
		component: login,
		meta: { layout: LayoutLogin, requiresAuth: false },
	},
	{
		path: "/:lang([a-z][a-z])?/:id?",
		name: "home",
		component: Home,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/welkom/:external_id?",
		name: "welcome",
		component: Welcome,
		meta: { layout: LayoutDefault, requiresAuth: false },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

let user = null;
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		Directus.getCurrentUser(Directus.getToken()).then((response) => {
			if (response == null) {
				next({
					name: "login",
					params: { nextUrl: to.fullPath },
					replace: true,
				});
			} else {
				user = response.data;
				console.log(to);
				next();
			}
		});
	} else {
		next();
	}
});

router.afterEach((to, from) => {
	router.currentRoute.params.user = user;
});

export default router;
